import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Team from "../components/Common/Team";
import FunFactsArea from "../components/Common/FunFactsArea";
import Partner from "../components/Common/Partner";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';

import AboutOne from "../assets/images/about-one.png";

const OzelYazilimlarArsiv = () => (
    <Layout>
        <SEO title="Misyon & Vizyon" /> 

        <Navbar />
        <PageBanner pageTitle="Pixel Yazılım" />

        <div className="about-area ptb-80">
            <div className="container">
               

                <div className="about-inner-area">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Vizyon</h3>
                                <p>Yazılım sektöründe, Türkiye’nin ve Dünya'nın bilinen markası olmak, Hizmet kalitesiyle müşterilerimizin birinci tercihi olarak sürekliliği sağlamak, kullanılabilecek en yeni teknolojiyi müşterilerimizin kullanımına sunmak ve dünya çapında etki oluşturmaktır</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Misyon</h3>
                                <p>Proje analiz etmek ve üretmek konusunda bilinen bir marka olmakla birlikte yazılım sektöründe işini severek yapan deneyimli ekibiyle müşterilerimize en iyi şekilde hizmet vermektir.Ayrıca çevresel duyarlılık bilinciyle malzeme israfını, iş gücü kayıplarını en aza indirmek</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Biz Kimiz?</h3>
                                <p>2005 yılında oyun platformlarında bilgisayarla tanışıp kurcalarken keyf aldığımı farkettim ve bu mesleği yapmam gerektiğini düşünerek çıktım yola.Şimdi ise hayallerim doğrultusunda ilerliyorum. Bir çok meslek dalından kişilerin ve fikirlerin bir araya gelmesi veya getirilmesi gerçekten heycan verici bir durum. Not: Bize katılmak için işinizi sevmeniz yeterlidir</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-8 col-md-12">
                        <div className="about-image">
                            <img src={AboutOne} alt="image" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="about-content">
                            <div className="section-title">
                                <h2>Hakkımızda</h2>
                                <div className="bar"></div>
                                <p>Sektörde 15 yıldan fazla hizmet vermiş ve 2020 yılında kendi ticaret hayatına başlayan bir yazılım firmasıyız</p>
                            </div>

                            <p>Firmalara , ticaret odalarına, fabrikalara, eğitim kurumlarına, hastanelere, özel şahıslara ve bir çok faliyet alanına hitap eden proje ve çözümler ürettik</p>

                            <p>Ülkemizde ve dünyada giderek yaygınlaşan ve ihtiyaç haline dönüşen yazılım hizmetlerinde önemli rol alarak; işinizle ilgili önemli detayları sizinle beraber analiz edip  özel yazılım projeleri geliştiriyoruz</p>

                            <p>Alanında uzman ekibimizle birlikte siz değerli müşterilerimizin güvenle kullanabileceği yazılımlar üretmeye ve projenin devamında sizinle beraber gelişmeye devam ediyoruz </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <Footer />
    </Layout>
)

export default OzelYazilimlarArsiv;
